import React from 'react';

import { PushSpinner as Spinner } from "react-spinners-kit";

const Loading = () => {
    let r = Math.round(Math.random() * 255);
    let g = Math.round(Math.random() * 255);
    let b = Math.round(Math.random() * 255);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
          <Spinner
            size={80}
            color={'rgb(' + r + ',' + g + ',' + b + ')'}
            loading={true}
          />
        </div>
    );
}

export default Loading;