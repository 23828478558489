/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import "./layout.css"

import { ThemeProvider, createTheme, Arwes, Button, Row, Col } from 'arwes';

const puffsProps = {
  quantity: 3
};

const myTheme = {
  color: {
    primary: {
      base: '#e8c467',
      dark: '#9d7521',
      light: '#f0d97e'
    },
    header: {
      base: '#e8c467',
      dark: '#9d7521',
      light: '#f0d97e'
    }
  },
  animTime: 500
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={createTheme(myTheme)}>
        <Arwes animate background={require("../images/background.jpg")} pattern={require('../images/pattern.png')} puffsProps={puffsProps}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content="Nicholas Wong: bioengineer, computer scientist, human @ Harvard '20, Google APM." />
            <meta name="keywords" content="nicholas wong, bioengineer, computer scientist, cybersecurity, google, harvard, nick wong" />
            <link rel="canonical" href={data.site.siteMetadata.url} />

            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Person",
                "url": data.site.siteMetadata.url,
                "name": "Nicholas Wong",
                "jobTitle": "APM",
                "alumniOf": {
                  "@type": "EducationalOrganization",
                  "name": "Harvard",
                  "legalName": "Harvard University"
                },
                "worksFor": {
                  "@type": "Organization",
                  "legalName": "Google LLC",
                  "name": "Google"
                }
              })}
            </script>
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Row align="center">
            <Col s={12}>
              {children}
            </Col>
          </Row>
          
          <Row align="center">
            <Col s={12} m={4} l={2}>
              <a href="/">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Home
                </Button>
              </a>
            </Col>

            <Col s={12} m={4} l={2}>
              <a href="/projects">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Projects
                </Button>
              </a>
            </Col>
              
            <Col s={12} m={4} l={2}>
              <a href="/work">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Work
                </Button>
              </a>
            </Col>

            <Col s={12} m={4} l={2}>
              <a href="/education">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Education
                </Button>
              </a>
            </Col>

            <Col s={12} m={4} l={2}>
              <a href="/leadership">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Leadership
                </Button>
              </a>
            </Col>
            
            <Col s={12} m={4} l={2}>
              <a href="/misc">
                <Button animate layer="control" style={{ paddingRight: "20px", width: "180px" }}>
                  Misc
                </Button>
              </a>
            </Col>
          </Row>
        </Arwes>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
